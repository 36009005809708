import React from 'react'
import { connect } from 'react-redux'
import { Link, navigate } from 'gatsby'
import { SEO, Header } from '../components'
import website from '../../config/website'
import {
  CartWrapper,
  Title,
  CartTable,
  CartItem,
  ItemTitle,
  ItemDescription,
  ItemPrice,
  ItemAction,
  CartBalance,
  NoItem,
  Order
} from '../components/Style/cartStyle';
import ShopBreadcrumb from '../components/ShopBreadcrumb'
import { removeItem } from '../actions/cart';

class Cart extends React.Component {

  // state = {
  //   removedItems: [],
  //   catalog: false
  // }

  // componentDidMount() {
  //   if (this.props.location.search) {
  //     const search = this.props.location.search.split('=');
  //     const isCatalog = (search[0].replace('?', '') === 'catalog' && search[1] === '1');
  //     this.setState({ catalog: isCatalog })
  //   }
  //   if (typeof localStorage !== 'undefined') {
  //     const cartItems = JSON.parse(localStorage.getItem('dischr-artworks-cart'));
  //     const removedItems = JSON.parse(localStorage.getItem('dischr-ordered-artworks'));

  //     if (removedItems) {
  //       cartItems.forEach((i) => {
  //         if (removedItems.filter(_i => _i === i.id).length === 1) {
  //           _self.props.removeItem(i);
  //         }
  //       });
  //       this.setState({ removedItems: removedItems });
  //       localStorage.removeItem('dischr-ordered-artworks');
  //     }

  //     const orderPath = JSON.parse(localStorage.getItem('dischr-order-path'));
  //     if (!orderPath) {
  //       localStorage.setItem('dischr-order-path', JSON.stringify(['cart']));
  //     }
  //   }
  // }

  // getCartBalance = () => {
  //   const reducer = (accumulator, currentValue) => accumulator + currentValue;
  //   const amounts = this.props.items.map(i => i.amount);
  //   return amounts.reduce(reducer);
  // }

  // toggleNotification = () => {
  //   this.setState({ notification: !this.state.notification });
  // }

  // removeItem = (item) => {
  //   this.props.removeItem(item);
  // }

  render() {
    // const {
    //   location,
    //   pageContext: { locale }
    // } = this.props;
    // const context = { slug: 'cart', parent: 'shop' };
    
    // return (
    //   <React.Fragment>
    //     <SEO title={`Disch Remy - Cart`} pathname={location.pathname} locale={locale} />
    //     <Header {...context} />
    //     <ShopBreadcrumb {...context} catalog={this.state.catalog} />
    //     <CartWrapper id={website.skipNavId}>
    //       <React.Fragment>
    //         <Title><h2>Your order</h2></Title>
    //         <NoItem>
    //           {
    //             this.state.removedItems.length > 0 && (
    //               <div className="infos">
    //                 Ho snap ! some artworks has been removed from your cart because they was already ordered.<br />
    //                 You may check out my others artworks...
    //               </div>
    //             )
    //           }
    //         </NoItem>
    //         {
    //           (this.props.items && this.props.items.length) ? (
    //             <CartTable>
    //               {
    //                 this.props.items.map((item, index) => {
    //                   return (
    //                     <CartItem key={`item-${index}`}>
    //                       <div>
    //                         <ItemTitle>
    //                           <Link to={`/shop/${item.uid}/`}>
    //                             {item.title}
    //                           </Link>
    //                         </ItemTitle>
    //                         <ItemDescription>
    //                           <p>{item.description}</p>
    //                         </ItemDescription>
    //                         <ItemPrice>
    //                           <span>{item.amount},00 €</span>
    //                         </ItemPrice>
    //                         <ItemAction onClick={() => this.removeItem(item)} />
    //                       </div>
    //                     </CartItem>
    //                   )
    //                 })
    //               }
    //               <CartBalance>
    //                 <div>{this.getCartBalance()},00{` `}€</div>
    //               </CartBalance>
    //               <Order>
    //                 {
    //                   this.state.catalog ? (
    //                     <button onClick={() => navigate(`/catalog/`)}>Back to catalog</button>
    //                   ) : (
    //                     <button onClick = { () => navigate(`/shop/`) }>Back to shop</button>
    //                   )
    //                 }
    //                 {
    //                   this.state.catalog ? (
    //                     <button className="orange" onClick={() => navigate(`/delivery/?catalog=1`)}>Complete order</button>
    //                   ) : (
    //                     <button className="orange" onClick={() => navigate(`/delivery/`)}>Complete order</button>
    //                   )
    //                 }
    //               </Order>
    //             </CartTable>
    //           ) : (
    //             <NoItem>
    //               <p>
    //                 {
    //                   this.state.catalog ? (
    //                     <React.Fragment>
    //                       You have no item, <Link to={`/catalog/`}>back to catalog ?</Link>
    //                     </React.Fragment>
    //                   ) : (
    //                     <React.Fragment>
    //                       You have no item, <Link to={`/shop/`}>back to shop ?</Link>
    //                     </React.Fragment>
    //                   )
    //                 }
    //               </p>
    //             </NoItem>
    //           )
    //         }
    //       </React.Fragment>
    //     </CartWrapper>
    //   </React.Fragment>
    // )
    return <div></div>
  }
}

const mapStateToProps = (state) => ({
  items: state.cart,
  orderedItems: state.order
});
const mapDispatchToProps = (dispatch) => ({
  removeItem: (item) => dispatch(removeItem(item))
});
export default connect(mapStateToProps, mapDispatchToProps)(Cart);