import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const ShopBreadcrumb = styled.div`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  max-width: 1072px;
  margin: 0 auto;
  padding: 0 2rem;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 24px;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    border-bottom: .1px solid #d0d0d0;
    li {
      list-style-type: none;
      height: 28px;
      display: flex;
      margin: 0;
      &:not(:last-of-type):after {
        content: "▸";
        display: inline-block;
        font-size: 28px;
        color: #aaa;
        line-height: 22px;
        margin: 0 10px;
        @media (max-width: ${props => props.theme.breakpoints.m}) {
          line-height: 20px;
        }
      }
      a, span {
        font-style: normal;
        font-weight: normal;
      }
      a {
        color: #494949;
        text-decoration: none;
        &:hover {
          color: #67471f
        }
      }
      
      &.disabled:after, span.disabled {
        cursor: default;
        color: #aaa;
      }
      &.linked:after, &.active:after, span.active {
        cursor: default;
        color: #67471f;
      }
    }
  }
`;

const checkPath = () => {
  if (typeof localStorage !== 'undefined') {
    const orderPath = JSON.parse(localStorage.getItem('dischr-order-path'));
    if (!orderPath) {
      return [];
    }
    return orderPath;
  }
}

export default (props) => {
  const orderPath = checkPath();
  return (
    <ShopBreadcrumb>
      {
        typeof orderPath !== 'undefined' && (
          <ul>
            <li className={props.slug === 'cart' ? 'active' : 'linked'}>
              {
                props.slug === 'cart' ? (
                  <span className="active">Cart</span>
                ) : (
                  <React.Fragment>
                    {
                      props.catalog ? (
                        <Link to="/cart/?catalog=1">Cart</Link>
                      ) : (
                        <Link to="/cart/">Cart</Link>
                      )
                    }
                  </React.Fragment>
                )
              }
            </li>
            <li className={
              props.slug === 'delivery' ? (
                'active'
              ) : (
                orderPath.indexOf('delivery') < 0 ? 'disabled' : 'linked'
              )}>
              {
                props.slug === 'delivery' ? (
                  <span className="active">Order delivery</span>
                ) : (
                  <React.Fragment>
                    {
                      orderPath.indexOf('delivery') < 0 ? (
                        <span className="disabled">Order delivery</span>
                      ) : (
                        <React.Fragment>
                          {
                            props.catalog ? (
                              <Link to="/delivery/?catalog=1">Order delivery</Link>
                            ) : (
                              <Link to="/delivery/">Order delivery</Link>
                            )
                          }
                        </React.Fragment>
                      )
                    }
                  </React.Fragment>
                )
              }
            </li>
            <li className={
              props.slug === 'payment' ? (
                'active'
              ) : (
                orderPath.indexOf('payment') < 0 ? 'disabled' : 'linked'
              )}>
              {
                props.slug === 'payment' ? (
                  <span className="active">Payment</span>
                ) : (
                  <React.Fragment>
                    {
                      orderPath.indexOf('payment') < 0 ? (
                        <span className="disabled">Payment</span>
                      ) : (
                        <React.Fragment>
                          {
                            props.catalog ? (
                              <Link to="/payment/?catalog=1">Payment</Link>
                            ) : (
                              <Link to="/payment/">Payment</Link>
                            )
                          }
                        </React.Fragment>
                      )
                    }
                  </React.Fragment>
                )
              }
            </li>
          </ul>
        )
      }
    </ShopBreadcrumb>
  );
}