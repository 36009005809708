import styled from '@emotion/styled'
import { Wrapper } from '../'
import Cross from '../Cross'

export const CartWrapper = Wrapper.withComponent('main')

export const Title = styled.div`
  h2 {
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 500;
    font-size: 26px;
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      text-align: center;
      margin-top: 2rem;
    }
  }
`;

export const NoItem = styled.div`
  p, a, .infos {
    font-family: ${props => props.theme.fontFamily};
    font-size: 16px!important;
    padding: 0;
    margin: 0 0 10px 0;
  }
  .infos {
    border: 1px solid #494949;
    padding: 15px 30px;
    margin-bottom: 30px;
    position: relative;
    text-align: center;
  }
  a {
    text-decoration: underline;
  }
`;

export const CartTable = styled.ul`
  margin: 0;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  div {
    display: flex;
  }
`;

export const CartItem = styled.li`
  list-style-type: none;
  display: block;
  height: 80px;
  border-bottom: 1px solid #ddd;

  div > div {
    height: 80px;
    padding: 5px;
    display: flex;
    align-items: center;
  }
`;

export const ItemTitle = styled.div`
  width: 20%;
  a {
    font-weight: 600;
    color: #494949;
    font-size: 18px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 50%;
  }
`;

export const ItemDescription = styled.div`
  width: 60%;
  p {
    font-size: 14px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none!important;
  }
`;

export const ItemPrice = styled.div`
  width: 10%;
  justify-content: flex-end;
  padding: 15px 10px 10px 0!important;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 40%;
  }
`;

export const ItemAction = styled(Cross)`
  width: 10%;
  justify-content: center;
  span {
    cursor: pointer;
  }
  img {
    width: 6px;
  }
`;

export const CartBalance = styled.div`
  width: 90%;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      justify-content: center;
    }
    padding: 5px;
    height: 80px;
    width: 100%;
    font-size: 26px;
  }
`;

export const Order = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${'' /* margin-top: 3rem; */}
  margin: 3rem auto 0;
  max-width: 80%;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    max-width: 100%;
  }
  button {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 15px 0;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      margin: 40px 5px 0;
    }
    background-color: #6d6d6d;
    &.orange {
      background-color: #67471f;
      border: 1px solid #67471f;
      &:hover {
        background: #9e6d30;
        box-shadow: 0 6px 9px rgba(50,50,93,0.06), 0 2px 5px rgba(0,0,0,0.08), inset 0 0 0 #999;
      }
    }
    box-shadow: 0 6px 9px rgba(50,50,93,0.06), 0 2px 5px rgba(0,0,0,0.08), inset 0 0 0 #393939;
    border-radius: 4px;
    border: 1px solid #6d6d6d;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    -webkit-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
    will-change: transform,background-color,box-shadow;
    outline: none;
    &:hover {
      background: #888;
      box-shadow: 0 6px 9px rgba(50,50,93,0.06), 0 2px 5px rgba(0,0,0,0.08), inset 0 0 0 #999;
    }
  }
`;

export const CartDeliveryForm = styled.form`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 16px;

  label, input, select {
    display: block;
    margin: 0;
    width: 100%;
  }

  label {
    padding: 0 0 5px 5px;
    font-weight: 600;
    small {
      display: block;

    }
  }
  input {
    padding-left: 10px;
  }
  input, select {
    height: 40px;
    outline: none;
  }
  
  select option {
    background: #dddddd;
  }
`;

export const FormBlocskWrapper = styled.div`
  display: flex;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }
  align-items: center;
  justify-content: space-between;

  > div {
    width: 50%;
    max-width: 520px;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      width: 100%;
    }
    &:first-of-type {
      padding-right: 20px;
    }
    &:last-of-type {
      padding-left: 20px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding-left: 0;
      }
    }
    > div {
      padding: 10px 0;
      &.subWrapper {
        display: flex;
        justify-content: space-between;
        > div {
          width: 45%;
        }
      }
    }
  }
`;

export const Submit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  button {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 40px 0 0;
    background-color: #6d6d6d;
    border: 1px solid #6d6d6d;
    &.orange {
      background-color: #67471f;
      border: 1px solid #67471f;
      &:hover {
        background: #9e6d30;
        box-shadow: 0 6px 9px rgba(50,50,93,0.06), 0 2px 5px rgba(0,0,0,0.08), inset 0 0 0 #999;
      }
    }
    box-shadow: 0 6px 9px rgba(50,50,93,0.06), 0 2px 5px rgba(0,0,0,0.08), inset 0 0 0 #393939;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    -webkit-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
    will-change: transform,background-color,box-shadow;
    outline: none;
    &:hover {
      background: #888;
      box-shadow: 0 6px 9px rgba(50,50,93,0.06), 0 2px 5px rgba(0,0,0,0.08), inset 0 0 0 #999;
    }
  }
`

export const PaymentForm = styled.div`

  .Form {
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    animation: fade 200ms ease-out;
    max-width: 500px;
    margin: 1rem auto 0;
    position: relative;
  }

  .FormGroup {
    margin: 0 0 20px;
    padding: 0;
    border-style: none;
    background-color: #fefefe;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ddd;
    border-radius: 4px;
  }

  .FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    border-top: 1px solid #ccc;
    padding-top: 8px;
  }

  .FormRow:first-of-type {
    border-top: none;
  }

  .FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    margin: 0;
    color: #494949;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @keyframes void-animation-out {
    0%,
    to {
      opacity: 1;
    }
  }
  .FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #ccc;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
  }

  .FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #494949;
    background-color: transparent;
    animation: 1ms void-animation-out;
    border: none;
  }

  .FormRowInput::placeholder {
    color: #aaa;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
  }

  .SubmitButton {
    font-family: 'Source Sans Pro','Roboto','Helvetica','Arial',sans-serif;
    display: block;
    font-size: 16px;
    width: 100%;
    height: 40px;
    margin: 40px 0 0;
    background-color: #67471f;
    border: 1px solid #67471f;
    &:hover {
      background-color: #9e6d30;
      border: 1px solid #9e6d30;
    }
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 0 0 #393939;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
  }

  .SubmitButton:active {
    background-color: #9e6d30;
    border: 1px solid #9e6d30;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 0 0 #9e6d30;
    transform: scale(0.99);
  }

  .SubmitButton.SubmitButton--error {
    transform: translateY(15px);
  }
  .SubmitButton.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
  }

  .SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #999;
    border: 1px solid #999;
    box-shadow: none;
  }

  .ErrorMessage {
    color: white;
    background: rgba(152,15,15,.8);
    border-radius: 4px;
    position: absolute;
    display: flex;
    border: 1px solid;
    justify-content: center;
    font-weight: bold;
    padding: 5px 15px;
    font-size: 13px;
    margin-top: 2px;
    width: 100%;
    transform: translateY(-15px);
    opacity: 1;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
  }

  .ErrorMessage svg {
    margin-right: 10px;
    display: none;
  }

  .Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
  }

  .ResultTitle {
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    color: #67471f;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 20px;
    text-align: center;
  }

  .ResultMessage {
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    color: #777;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
  }

  .ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
    outline: none;
  }
`;

export const PaymentNotice = styled.div`
  margin-top: 1rem;
  text-align: center;
  hr {
    display: block;
    max-width: 200px;
    margin: 0 auto;
    border-bottom: 1px solid #fff;
  }
  p {
    padding-top: 8px;
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    text-align: center;
    text-transform: uppercase;
    color: #999;
    text-shadow: white 1px 1px;
    font-size: 12px;
    cursor: default;
    margin: 2px 0 10px 0;
    span {
      display: flex;
      align-items: top;
      justify-content: center;
      span {
        display: block;
        padding: 0 2px;
        margin: 0 3px;
        &.lock {
          width: 15px;
          opacity: .5;
        }
        &.centered {
          padding-top: 3px;
        }
      }
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    margin: 10px 0 0 0;
    li {
      margin: 0 5px;
      display: inline-block;
      width: 30px;
      img {
        opacity: .5;
        width: 100%;
      }
    }
  }
`;