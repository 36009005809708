import React from 'react'
import styled from '@emotion/styled'
import Prev from '../../static/prev.svg'
import Next from '../../static/next.svg'

const CrossAction = styled.div`
  ${'' /* width: 12px;
  justify-content: center;
  display: inline-block;
  span {
    cursor: pointer;
  }
  img {
    width: 6px;
  } */}
  width: 10%;
  &.fullwidth {
    width: 100%;
  }
  justify-content: center;
  span {
    cursor: pointer;
  }
  img {
    width: 6px;
  }
`;

export default ({ onClick, fullwidth }) => (
  <CrossAction onClick={onClick} className={fullwidth ? 'fullwidth' : ''}>
    <span>
      <img src={Next} />
      <img src={Prev} />
    </span>
  </CrossAction>
)
